.btn {
  align-items: center;
  border: none;
  border-radius: rem(5px);
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  // padding: rem(5px) rem(10px);
  padding: rem(10px) rem(16px);
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: color, background-color;

  &:hover {
    text-decoration: none;
  }

  & > svg {
    color: $blue-primary-800;
    margin-right: rem(10px);
  }

  &.iconOnly {
    background-color: transparent;
    padding: 0;

    svg {
      margin-right: 0;
    }
  }

  &.primary {
    background: $gradient-blue-primary;
    background-color: $cyan-500;
    color: $white;
    justify-content: center;

    &:disabled {
      background: #d3d4dd none;
      color: #8f8fa2;
      cursor: auto;
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      background: none;
      background-color: $cyan-500;
    }

    &:active {
      border-color: $cyan-500;
    }
  }

  &.primary-clear-blue {
    background-color: $blue-primary-500;
    border: 1px solid $blue-primary-300;
    color: $white;
  }

  &.outline-primary {
    background-color: $gray-scale-100;
    border: 1px solid $primary-blue;
    color: $primary-blue;

    &:focus,
    &:hover {
      background-color: $gray-scale-200;
      border-color: $blue-primary-500;
      color: $blue-primary-600;
      text-decoration: none;
    }
  }

  &.textButton {
    background-color: transparent;
    border-radius: 0;
    color: $cyan-600;
    padding: 0;
  }

  &.greyWithIcon {
    background-color: $background-grey;
    border: 1px solid $background-grey;
    color: $text-grey;
    text-align: left;

    &:hover {
      border: 1px solid $primary-blue;
    }

    span {
      margin-left: rem(16px);
    }
  }

  &.yellow {
    background-color: $yellow-400;
    border: rem(3px) solid $yellow-400;
    color: $blue-primary-800;
    text-decoration: none;

    &:focus,
    &:hover {
      background-color: $yellow-600;
      border-color: $yellow-800;
    }

    &:active {
      background-color: $yellow-400;
    }

    &.withoutText svg {
      margin: 0;
    }
  }
}

button:focus {
  outline: none;
}
