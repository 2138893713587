@import "atoms/variables";@import "functions";
@import 'normalize';

@import '../node_modules/react-input-range/lib/css/index.css';

// Atoms
@import 'atoms/buttons';
@import 'atoms/inputs';

// Molecules
@import 'molecules/checkbox-container';
@import 'molecules/picture-input-container';
@import 'molecules/radio-container';

body {
  background-color: $gray-scale-50;
}

* {
  font-family: $main-font;
  box-sizing: border-box;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-scale-900;
}

label,
p {
  &.required::before {
    color: $red-600;
    content: '*';
    font-size: rem(20px);
    margin-right: rem(4px);
    position: relative;
    top: rem(2px);
  }
}

a {
  color: $semantic-links;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.linkWithArrow {
  align-items: center;
  display: flex;
  font-weight: 500;
  text-decoration: none;

  svg {
    color: $primary-blue;
    margin-left: rem(10px);
  }
}

h3 {
  font-size: 28.83px;
  font-weight: 500;
  margin: 0;
}

h4 {
  font-size: 22.78px;
  font-weight: 500;
  margin: 0;
}

p {
  margin: 0;
}

.tabla-rap {
  background-color: $white;
  border: 1px solid $gray-scale-200;
  border-radius: rem(10px);
  border-spacing: 0;
  width: 100%;
  overflow: hidden;

  @media (max-width: $mobile-width) {
    border-radius: 0;
  }

  th,
  td {
    @media (min-width: $mobile-width) {
      border-bottom: 1px solid $gray-scale-200;
    }
  }

  thead {
    background-color: $yellow-50;

    th {
      color: $semantic-warning-2;
      font-size: rem(14px);
      font-weight: 500;
      padding: rem(13px) rem(16px);
      text-align: left;
    }

    @media (max-width: $mobile-width) {
      display: none;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $gray-scale-500;

      @media (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
      }

      td {
        display: flex;
        font-weight: 400;
        justify-content: space-between;
        padding-left: rem(16px);
        padding-right: rem(16px);
        padding-top: rem(16px);
        padding-bottom: rem(12px);

        @media (min-width: $mobile-width) {
          display: table-cell;
        }

        & > span:first-child {
          @media (min-width: $mobile-width) {
            display: none;
          }
        }

        button {
          margin-left: rem(15px);
        }

        &:last-child svg {
          color: $primary-blue;
        }
      }
    }
  }
}



main {
  overflow: hidden;
}

[hidden] {
  display: none !important;
}

.max-width-container {
  display: flex;
  margin: 0 auto;
  max-width: $max-content-width;
}

.modalContainer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  .modal {
    background-color: $white;
    border-radius: rem(10px);
    margin: 0 rem(16px);
    padding: rem(16px);
    position: relative;
  }
}

.requiredLabel {
  font-size: rem(18px);
  font-weight: 500;
  margin-bottom: rem(8px) !important;

  &::before {
    content: '*';
    color: $semantic-error-2;
    margin-bottom: rem(8px);
    margin-right: rem(4px);
  }
}

.input-range {
  .input-range__label {
    display: none;
  }

  .input-range__track--active {
    background: $semantic-success;
  }

  .input-range__slider {
    background-color: $white;
    border: rem(2px) solid $primary-blue;
  }
}

.react-select__control {
  box-shadow: none !important;

  &:hover {
    border-color: $gray-scale-500 !important;
  }

  .react-select__value-container--is-multi .react-select__multi-value {
    background-color: $blue-primary-700;
    border-radius: rem(25px);
    padding: rem(5px) rem(10px);

    & > div:first-child,
    & > div:last-child svg {
      color: $white;
    }
  }
}

.react-select__menu {
  position: relative !important;
}

.RNTtoValidate {
  color: $cyan-600;
}

.RNTrejected {
  color: $red-700;
}

.RNTexpired {
  color: $yellow-700;
}

.RNTvalidated {
  color: $green-700;
}

.error {
  color: $semantic-error;
  margin-bottom: rem(8px);
}
// Manage offer slider styles list container
.custom-slick{
  & > .slick-list {
    height: 530px;
  }
  @media (max-width: 1200px) {
    height: 530px;
  }
  @media (max-width: $mobile-width) {
    height: 550px;
  }
}
.custom-slick-black-arrows{
  .slick-arrow.slick-prev{
    color: black;
    opacity: 1;    
    margin-left: 3em;
  }
  .slick-arrow.slick-next{
    color: black;
    opacity: 1;
    padding-right: 5em;    
  }
}

.slick-dots.slick-dots-bottom {
  bottom: 0px;

  & > li.slick-active{
    & > button{
      background-color: white;
    }
  }
  & > li{    
    & > button{
      background-color: white;
    }
  }
}

.slick-arrow.slick-prev::after {
  width: 30px;
  height: 30px;
  border-inline-width: 3px 0;
    border-block-width: 3px 0;
}
.slick-arrow.slick-next::after {
  width: 30px;
  height: 30px;
  border-inline-width: 3px 0;
    border-block-width: 3px 0;
}