input,
select,
textarea {
  border: 1px solid $gray-scale-600;
  border-radius: 5px;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    box-shadow: 0px 0px 8px 2px $blue-primary-100;
    border-color: $blue-primary-400;
  }

  &.success {
    border-color: $semantic-success;
  }

  &.error {
    border-color: $semantic-error;
  }
}

input,
textarea {
  outline: none;

  &:disabled {
    background: $white;
    border: none;
    padding: 0 !important;
  }
}

label {
  font-size: rem(14.22px);
}

input:not([type='checkbox']),
select {
  width: 100%;
}

input:not([type='date']),
textarea {
  padding: rem(13px) rem(8px);
}

input[type='date'],
select {
  padding: rem(11px) rem(8px);
}

textarea {
  height: rem(84px);
  width: 100%;
}

input[type='password'] {
  color: $gray-scale-600;
}

select:disabled {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  opacity: 1;
  padding: 0;

  &,
  & option {
    color: $gray-scale-900 !important;
  }
}

button {
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: rem(43px);
  height: rem(19px);

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $green-500;

      &::before {
        background-color: $semantic-success;
        border-color: $semantic-success;
        transform: translateX(26px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: rem(14px);

    &::before {
      border: rem(2px) solid $gray-scale-400;
      position: absolute;
      content: '';
      height: rem(22px);
      width: rem(22px);
      left: rem(-4px);
      bottom: rem(-4px);
      background-color: $white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.range-picker-custom {
  border: 1px solid $gray-scale-200;
  border-radius: rem(10px);
  margin-top: rem(20px);
  overflow: hidden;
  width: 100%;

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDateRangeWrapper {
    width: 100%;

    .rdrMonth {
      width: 100%;

      .rdrDayToday .rdrDayNumber span::after {
        background-color: $green-800;
      }
    }
  }
}
