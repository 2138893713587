.picture-container {
  .help {
    display: block;
    font-size: rem(14px);
  }
  .label {
    font-size: rem(14px);
    font-weight: bold;
  }

  .picture {
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
  .inputFileContainer {
    margin: rem(14px) 0 0;
    position: relative;
    
    input[type="file"] {
      opacity: 0;
    }
    .btn {
      font-size: rem(14px);
      padding: rem(5px) rem(10px);
      width: rem(114px);
    }
    .upload-btn {
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}