.radioContainer {
  align-items: center;
  display: flex;

  input[type="radio"] {
    display: none;

    & + span {
      align-items: center;
      display: flex;
      position: relative;

      &:before {
        border: 0.526316px solid $primary-blue;
        border-radius: 50%;
        content: '';
        display: block;
        height: rem(24px);
        margin-right: rem(10px);
        width: rem(24px);
      }

      &:after {
        border: 0.526316px solid $primary-blue;
        border-radius: 50%;
        content: '';
        display: block;
        height: rem(16px);
        left: rem(4px);
        position: absolute;
        top: rem(4px);
        width: rem(16px);
      }
    }

    &:checked {
      & + span {
        &:after {
          background-color: $semantic-success;
        }
      }
    }
  }

  &.small input[type=radio] {
    & + span {
      &::before {
        height: rem(18px);
        width: rem(18px);
      }

      &::after {
        height: rem(12px);
        left: rem(3px);
        top: rem(3px);
        width: rem(12px);
      }
    }
  }
}
